import React from 'react'
import { LayoutIR } from '../../components/organisms/Layout/LayoutIR'
import { SEOIR } from '../../components/data/SEO'
import { ENGLISH_TITLE } from '../../components/data/English'

const data = [
  {
    head: '11月中旬',
    body: '決算発表',
  },
  {
    head: '12月中旬',
    body: '定時株主総会',
  },
  {
    head: '2月中旬',
    body: '第1四半期決算発表',
  },
  {
    head: '5月中旬',
    body: '第2四半期決算発表',
  },
  {
    head: '8月中旬',
    body: '第3四半期決算発表',
  },
]

const Page = () => {
  return (
    <LayoutIR
      heading="IRスケジュール"
      headingEnglish={ENGLISH_TITLE.IRSchedule}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: 'IRスケジュール', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pb-12 bg-white">
        <div className="mb-10 block pc:hidden">
          <img src="/ir/img/scheduleSP.png" alt="IRスケジュール" />
        </div>
        <div className="mb-10 pc:mb-24 hidden pc:block">
          <img src="/ir/img/schedulePC.png" alt="IRスケジュール" />
        </div>
        <ul>
          {data.map((item) => (
            <li
              key={item.body}
              className="flex px-6 py-6 pc:py-7 first-of-type:border-t border-b border-border10"
            >
              <p
                className="text-sm pc:text-md w-28 pc:w-48 leading-tight font-bold"
                dangerouslySetInnerHTML={{ __html: item.head }}
              />
              <p
                className="text-sm pc:text-md leading-tight"
                dangerouslySetInnerHTML={{ __html: item.body }}
              />
            </li>
          ))}
        </ul>
        <p className="pt-6 text-xs pc:text-right">
          ※都合により予告なく変更する場合があります。ご了承ください。
        </p>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEOIR title="IRスケジュール" url="/ir/schedule" />
